<template>
  <div class="car-identification">
    <Header
      :isHeader="true"
      :back="true"
      :mobileResume="true"
      :carInfo="true"
      :dealer="true"
      :services="true"
      :mobility="true"
      :wash="true"
      :schedule="true"
      :step="currentStepIndex"
    />
    <Wrapper :ismain="true">
      <template v-slot:sidebar>
        <Sidebar
          :desktopResume="true"
          :dealer="true"
          :services="true"
          :mobility="true"
          :wash="true"
          :schedule="true"
        />
      </template>
      <Title
        titleStyle="title"
        :text="translateString('insertContactDetails')"
      />
      <Input
        id="name"
        :label="translateString('name')"
        :placeholder="translateString('insertName')"
        @updateInput="updateInput($event, 'name')"
        :errorMsg="translateString('insertNameError')"
        :value="contactInfo.name"
        autocomplete="name"
        v-model="contactInfo.name"
        type="text"
        @submit="next('getInfo')"
      />
      <Input
        id="email"
        label="Email"
        type="email"
        autocomplete="email"
        v-model="contactInfo.email"
        :placeholder="translateString('insertEmail')"
        @updateInput="updateInput($event, 'email')"
        @changeInput="changeInput($event, 'email')"
        :errorMsg="translateString('insertValidEmail')"
        :value="contactInfo.email"
        @submit="next('getInfo')"
      />
      <Input
        type="tel"
        autocomplete="tel"
        id="phone"
        v-model="contactInfo.phone"
        :label="translateString('phone')"
        :placeholder="translateString('insertPhone')"
        @updateInput="updateInput($event, 'phone')"
        :errorMsg="translateString('insertValidPhone')"
        :value="contactInfo.phone"
        @submit="next()"
      />
      <div v-if="country == 'pt'">
        <InputCheckbox
          id="acceptRGPD"
          :label="translateString('acceptRGPD')"
          :checked="contactInfo.acceptRGPD"
          :links="links"
          value="1"
          @updateInput="updateInput($event, 'acceptRGPD', 'checkbox')"
        />
        <InputCheckbox
          v-if="!hasCG"
          id="createAccount"
          :label="translateString('createAccount')"
          :checked="contactInfo.createAccount"
          value="1"
          @updateInput="updateInput($event, 'createAccount', 'checkbox')"
        />
        <InputCheckbox
          id="consentCenter"
          :label="translateString('consentCenter')"
          :checked="contactInfo.consentCenter"
          value="1"
          @updateInput="updateInput($event, 'consentCenter', 'checkbox')"
        />
        <p class="xs-text align-left margin-button">
          {{ translateString("acceptRGPDError") }}
        </p>
      </div>

      <template v-slot:footer>
        <Footer>
          <Button
            :label="translateString('continue')"
            :btnClass="ctaBtn"
            id="getContactInfo"
            class="btn-primary align-btn-footer"
            @action="next()"
          />
        </Footer>
      </template>
    </Wrapper>
  </div>
</template>

<script>
import Button from "@/components/Buttons/Button.vue";
import Footer from "@/components/Footer/Footer.vue";
import Header from "@/components/Header/Header.vue";
import Input from "@/components/Inputs/Input.vue";
import InputCheckbox from "@/components/Inputs/InputCheckbox.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import Title from "@/components/Title/Title.vue";
import Wrapper from "@/components/Wrapper/Wrapper.vue";
import { mapGetters, mapState } from "vuex";
import Cookies from "js-cookie";


import { translateString } from "@/functions/functions.js";
import { validateInputs } from "@/functions/validations.js";
import { country } from "@/services/country";

export default {
  name: "ContactInfo",
  components: {
    Button,
    Footer,
    Header,
    Input,
    InputCheckbox,
    Sidebar,
    Title,
    Wrapper,
  },
  data() {
    return {
      name: '',
      email: '',
      phone: '',
      country,
      ctaBtn: "disabled ",
      contactInfo: this.$store.state.contactInfo,
      cgName: this.$store.state.cgName,
      cgEmail: this.$store.state.cgEmail,
      cgPhone: this.$store.state.cgPhone,
      requiredFields:
        country == "pt"
          ? ["name", "email", "phone", "acceptRGPD"]
          : ["name", "email", "phone"],
      links: [
        "https://www.caetanoretail.pt/politica-de-privacidade/",
        "https://www.caetanoretail.pt/termos-de-servico/",
      ],
    };
  },
  computed: {
    finalError() {
      return this.$store.state.finalError;
    },
    hasCG() {
      return this.$store.state.hasCG;
    },
    ...mapGetters(["getCurrentStepIndex"]),
    ...mapState(['authenticated', 'userInfo']),

    currentStepIndex() {
      return this.$store.state.currentStepIndex;
    },
  },
  created() {
    /* this.setCookie(); */
    this.$store.dispatch("selectOption", {
      selectedValue: "finalError",
      value: false,
    });
    if (this.$store.state.carPlate == "") {
      //this.$router.replace({ name: "Homepage" });
    }
    const validateContactInfo = this.createRequiredFieldsObject(
      this.contactInfo
    );
    if (
      Object.values(validateContactInfo).some(
        (x) => x === null || x === "" || x === false
      )
    ) {
      this.ctaBtn = "disabled ";
    } else {
      this.ctaBtn = "";
    }
    if (this.cgName != "") {
      this.contactInfo.name = this.cgName;
    }
    if (this.cgEmail != "") {
      this.contactInfo.email = this.cgEmail;
    }
    if (this.cgPhone != "") {
      this.contactInfo.phone = this.cgPhone;
    }
    if (this.userInfo && this.userInfo.mail) {
      this.contactInfo.email = this.userInfo.mail;
    }
  },
  methods: {
    validateInputs,
    translateString,
    createRequiredFieldsObject(contactInfo) {
      return Object.keys(contactInfo)
        .filter((x) => this.requiredFields.includes(x))
        .reduce((cur, key) => {
          return Object.assign(cur, { [key]: this.contactInfo[key] });
        }, {});
    },
    updateInput(value, field, type = null) {
      if (type != "checkbox") {
        this.contactInfo[field] = value;
      } else {
        this.contactInfo[field] = value.checked;
      }
      if (field === 'name') {
        this.onNameChange();
      }
    },
    changeInput(value, field) {
      if (field == "email") {
        this.$store.dispatch("searchUserCaetanoGo");
        this.$store.dispatch("selectOption", {
          selectedValue: "isLoading",
          value: "getContactInfo",
        });
      }
    },
    //Metodo que buscará o cookie com dados do user para preencher Nome, Email e Phone AutoComplete
    onNameChange() {
      const userDetailsCookie = Cookies.get(this.contactInfo.name);

      if (userDetailsCookie) {
        const userDetails = JSON.parse(userDetailsCookie);
        this.contactInfo.name = userDetails.name;
        this.contactInfo.email = userDetails.email;
        this.contactInfo.phone = userDetails.phone;
      }
    },
    next() {
      const validateContactInfo = this.createRequiredFieldsObject(
        this.contactInfo
      );
      if (
        Object.values(validateContactInfo).some(
          (x) => x !== null && x !== "" && x !== false
        )
      ) {
        if (validateInputs(["email", "phone"]).length == 0) {
          this.$store.dispatch(
            "setCurrentStepIndex",
            this.currentStepIndex + 1
          );
          this.$store.dispatch("selectOption", {
            selectedValue: "contactInfo",
            value: this.contactInfo,
          });
          this.$router.push({ name: "Resume" });
        }
      }
    },
  },
  watch: {
    contactInfo: {
      handler(contactInfo) {
        const validateContactInfo =
          this.createRequiredFieldsObject(contactInfo);
        if (
          Object.values(validateContactInfo).some(
            (x) => x === null || x === "" || x === false
          )
        ) {
          this.ctaBtn = "disabled ";
        } else {
          this.ctaBtn = "";
        }
      },
      deep: true,
    },
    finalError() {
      if (this.finalError == true) {
        this.$router.push({ name: "ErrorForm" });
      }
    },
    authenticated(newValue) {
      if (newValue === 1 && this.userInfo && this.userInfo.mail) {
        this.contactInfo.email = this.userInfo.mail;
      }
    },
  },
};
</script>
